<script setup>
import {Head, Link, useForm, usePage} from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import CoreboxLogoLarge from '../../Components/Corebox/CoreboxLogoLarge.vue';
import AuthLayout from '../../Layouts/AuthLayout.vue';
import BaseTextInput from '../../Components/Shared/Inputs/BaseTextInput.vue';
import PrimaryButton from '../../Components/Shared/PrimaryButton.vue';
import BaseCheckbox from '../../Components/Shared/Inputs/BaseCheckbox.vue';
import { onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useI18n } from 'vue-i18n';

defineProps({
    canResetPassword: Boolean,
    status:           String,
});

const form = useForm({
    email:    '',
    password: '',
    remember: false,
});

const page  = usePage();
const toast = useToast({
    position: 'top',
    timeout:  5000,
});
const { t } = useI18n();

onMounted(() => {
    if (page?.props?.route?.query?.message) {
        toast.success(t(page.props.route.query.message));
    }
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <Head title="Log in"/>

    <auth-layout>
        <authentication-card>
            <template #logo>
                <corebox-logo-large class="mx-auto"/>
            </template>

            <div v-if="status" class="status-message">
                {{ status }}
            </div>

            <form @submit.prevent="submit">
                <div>
                    <base-text-input
                        id="email"
                        v-model="form.email"
                        autocomplete="username"
                        autofocus
                        class="block w-full"
                        label="form.labels.email"
                        required
                        type="email"
                        :error="form.errors.email"
                    />
                </div>

                <div class="password-block">
                    <base-text-input
                        id="password"
                        v-model="form.password"
                        autocomplete="current-password"
                        class="block w-full"
                        label="form.labels.password"
                        required
                        type="password"
                        :error="form.errors.password"
                    />
                </div>

                <div class="block mt-2">
                    <label class="flex items-center remember">
                        <base-checkbox v-model:checked="form.remember" label="remember_me" name="remember"/>
                    </label>
                    <Link v-if="canResetPassword" :href="route('password.request')"
                          class="forgot-password">
                        {{ $t('forgot_password') }}
                    </Link>
                </div>

                <div class="block submit-block">
                    <primary-button :disabled="form.processing" class="no-flex">
                        <span>{{ $t('login') }}</span>
                    </primary-button>
                </div>
            </form>
        </authentication-card>
    </auth-layout>
</template>


<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .mt-2 {
        margin-top: .5rem;
    }

    .mt-4 {
        margin-top: 1rem;
    }
}

.remember {
    margin: 0;
    align-items: center;
}

.block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.8rem;
}

.password-block {
    margin-top: 1.8rem;
}

.submit-block {
    margin-top: var(--padding-lg);
}

.forgot-password {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: var(--padding-lg);

    color: var(--corebox-primary);
    text-decoration: none;
}

.status-message {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.2rem;
    color: #16a34a;
    line-height: 1.25rem;

    /* Dark mode support */
    @media (prefers-color-scheme: dark) {
        color: #4ade80; /* Equivalent to Tailwind's dark:text-green-400 */
    }
}
</style>
